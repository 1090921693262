$(document).ready(function() {
    $("img").on("contextmenu",function(){
       return false;
    }); 
}); 

var main = {

    _init: function () {

        this.registerEvents();
        this.startSlider();

        this.transformSVGToInline();

    },
    

    registerEvents: function () {

        $(document).on('click', 'header nav a[href^="#"]', function (event) {
            event.preventDefault();
        
           $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 200
            }, 500);
        });


        $(window).scroll(function (event) {

            var scroll = $(window).scrollTop();
            
            if( scroll > 0 )
                $( 'header' ).addClass( 'sticky' );
            else
                $( 'header' ).removeClass( 'sticky' );

        });

    },

    startSlider: function() {

        $(document).ready(function(){
            $(".owl-carousel").owlCarousel({
                items: 1,
                loop: true
            });
        });

    },

    myMap:  function() {
        var mapOptions = {
            center: new google.maps.LatLng(51.5, -0.12),
            zoom: 10,
            mapTypeId: google.maps.MapTypeId.HYBRID
        }
    var map = new google.maps.Map(document.getElementById("map"), mapOptions);
    },

    transformSVGToInline: function () {

        $('img.svg').each(function () {

            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function (data) {

                var $svg = $(data).find('svg');

                if (typeof imgID !== 'undefined')
                    $svg = $svg.attr('id', imgID);

                if (typeof imgClass !== 'undefined')
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');

                $svg = $svg.removeAttr('xmlns:a');
                $img.replaceWith($svg);

            }, 'xml');

        });

    }

};


$(document).ready(function () {

    window.sr = ScrollReveal();

    main._init();
});